export default {
  classType: 'components',

  init(options) {
    this.el.addEventListener('click', this.click.bind(this));
  },

  click(e) {
    e.preventDefault();
    this.app.modal.get(e.currentTarget.getAttribute('href'));
  },
};
